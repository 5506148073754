import QRCode from "qrcode";
import pako from "pako";

export const hashCode = (s: string): string => {
  const seq = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_";
  let code = s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0) >>> 0;
  let hash = "";
  while (code > 0) {
    hash += seq[code % seq.length];
    code = Math.floor(code / seq.length);
  }
  return hash; 
}

export const calculateBestHashLength = (ss: string[]): number => {
  const hashed = ss.map(s => hashCode(s));
  let length = 0;
  while(true) {
    length++;
    const shortenedHashes = hashed.map(h => h.substr(0, length));
    if (shortenedHashes.some((h, i) => shortenedHashes.indexOf(h) !== i)) continue;
    return length;
  }
}

const extractUniqueKeyGroups = (keys: string[]): string[] => {
  const regex = /(.*?)(_[1-9][0-9]*)?$/;
  const keyGroups = keys.map(key => {
    const match = regex.exec(key);
    return match[1];
  });
  return keyGroups.filter((key, i) => keyGroups.indexOf(key) === i);
}

export const deflateQRCodePayload = (data: { [keys: string]: any }): { [keys: string]: any } => {
  const preprocessed = Object.fromEntries(Object.entries(data).flatMap(entry => {
    const key = entry[0];
    const value = entry[1];
    if (!value) return [];
    if (typeof value !== "object") return [[key, value]];
    // TODO: qrcode用のデータ作成機能をFieldInputElementに実装する
    // japanese calendarのデータ最適化
    if (value["gengo"] && value["year"] && value["month"] && value["date"]) {
      return [[key, `${value["gengo"]},${value["year"]},${value["month"]},${value["date"]}`]];
    }
    // select_country
    if (value["akasatana"] && value["country"]) {
      return [[key, `${value["akasatana"]},${value["country"]}`]];
    }

    // simple_year_month
    if (value["years"] !== undefined && value["months"] !== undefined) {
      return [[key, `${value["years"]},${value["months"]}`]];
    }
    const newValue = {...value};
    if (value["gengo_year"] !== undefined) {
      delete newValue["gengo_year"];
    }
    if (value["full"] !== undefined) {
      delete newValue["full"];
    }
    return [[key, newValue]];
  }));
  const uniqueKeyGroups = extractUniqueKeyGroups(Object.keys(data));
  const length = calculateBestHashLength(uniqueKeyGroups);
  const optimized = {};
  uniqueKeyGroups.forEach(keyGroup => {
    const hashedKeyGroup = hashCode(keyGroup).substr(0, length);
    if (preprocessed[keyGroup]) {
      optimized[hashedKeyGroup] = preprocessed[keyGroup];
    }
    const keyGroupMembers = Object.keys(preprocessed).filter(key => key.match(new RegExp(keyGroup + "_([1-9][0-9]*)"))).sort();
    if (keyGroupMembers.length > 0) {
      const max = parseInt(keyGroupMembers[keyGroupMembers.length - 1].replace(`${keyGroup}_`, ""));
      const arrayKey = `[${hashedKeyGroup}]`;
      optimized[arrayKey] = [];
      const membersKeyMap = {};
      keyGroupMembers.forEach(key => {
        const id = key.replace(`${keyGroup}_`, "");
        membersKeyMap[id] = key;
      })
      for (let i = 1; i <= max; i++) {
        const memberKey = membersKeyMap[String(i)];
        if (memberKey) {
          optimized[arrayKey].push(preprocessed[memberKey])
        } else {
          optimized[arrayKey].push("");
        }
      }
    }
  });
  return optimized;
}

export const inflateQRCodePayload = (keys: string[], data: { [keys: string]: any }): { [keys: string]: any } => {
  const uniqueKeyGroups = extractUniqueKeyGroups(keys);
  const length = calculateBestHashLength(uniqueKeyGroups);
  const hashKeyMap = Object.fromEntries(uniqueKeyGroups.map(key => [hashCode(key).substr(0, length), key]));
  return Object.fromEntries(Object.entries(data).flatMap(entry => {
    const key = entry[0];
    const value = entry[1];
    if (key.startsWith("[") && key.endsWith("]")) {
      const actualKey = key.substring(1, key.length - 1);
      return (value as any[]).map((v, i) => [`${hashKeyMap[actualKey]}_${i + 1}`, value[i]]);
    } else {
      return [[hashKeyMap[key] || key, value]];
    }
  }));
}

export const buildQRCodeDataURL = async (title: string, rawData: { [keys: string]: any }): Promise<string> => {
  const data = deflateQRCodePayload(rawData);
  const payload = JSON.stringify({
      title,
      data
  });
  const compressed = pako.deflate(payload, { 
    to: "string",
  });
  return QRCode.toDataURL(compressed, {
    errorCorrectionLevel: "M"
  });
};