import * as megane from "meganets/dist/megane";
import { DigitalFormEditorApplication } from "../../../src/main";
import { FormDefinition } from "../formData";
import { DigitalApplicationDefinition } from "../../../src/digitalForm";
import { } from "../../../src/templates";
import { parse as parseURL } from "url";
import * as querystring from "querystring";
import "./styles/main.scss";

const url = parseURL(location.href);
const query = querystring.parse(url.query);
const definition: DigitalApplicationDefinition = {
  pdfFileName: "申請ナビ書類",
  pdfMetaTitle: "申請ナビ",
  forms: []
};
if (query.forms) {
  const string = decodeURIComponent(query.forms);
  const list = string.split(",");
  for (let i = 0, l = list.length; i < l; i++) {
    const index = parseInt(list[i]) - 1;
    definition.forms.push(FormDefinition.forms[index]);
  }
} else {
  definition.forms = FormDefinition.forms;
}
definition.extraSetup = FormDefinition.extraSetup;
definition.extraPages = FormDefinition.extraPages;
definition.onFinishPreview = FormDefinition.onFinishPreview;

const frame = new megane.ViewControllerFrame(document.getElementById("container"));
const template = new megane.TemplateManager(document.getElementById("template"));
const app = new DigitalFormEditorApplication(definition, frame, template);

window.addEventListener("load", () => {
  app.onAppReady();
});
window.addEventListener('beforeunload', (e) => {
  // @ts-ignore
  if (!__IS_DEVSERVER__) {
    e.returnValue = "編集中のデータが失われますがよろしいですか？";
  }
});

const script = <HTMLScriptElement>document.createElement("script");
// @ts-ignore
script.src = `https://maps.googleapis.com/maps/api/js?key=${__GOOGLE_MAP_API_KEY__}&callback=initMap`;
script.async = true;
script.defer = true;
document.body.appendChild(script);
