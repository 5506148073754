import {
    DigitalApplicationDefinition, DigitalFormDefinition, sync
} from '../../../src/digitalForm';

import { } from "../src/templates";

import emergency_hiring from "./emergency_hiring.json";
import mousitate from "./mousitate.json";

import { DigitalForm } from '../../../src/DigitalForm/DigitalForm';
import { Notifier } from '../../../src/main';

export const FormDefinition: DigitalApplicationDefinition = {
    forms: [
        <DigitalFormDefinition>emergency_hiring,
        <DigitalFormDefinition>mousitate
    ],
    extraSetup: (formMap: { [keys: string]: DigitalForm }, definitionMap: { [keys: string]: DigitalFormDefinition }) => {
        const { 
            emergency_hiring,
            mousitate
         } = formMap;

        sync(
            [emergency_hiring, "notification_date"],
            [mousitate, "notification_date"]
        );
        sync(
            [emergency_hiring, "notifier_address"],
            [mousitate, "notifier_address"]
        );
        sync(
            [emergency_hiring, "private_name"],
            [mousitate, "private_name"]
        );
        sync(
            [emergency_hiring, "company_name"],
            [mousitate, "company_name"]
        );
        sync(
            [emergency_hiring, "ceo"],
            [mousitate, "ceo"]
        );
        sync(
            [emergency_hiring, "ceo_name"],
            [mousitate, "ceo_name"]
        );
        sync(
            [emergency_hiring, "houjin"],
            [mousitate, "houjin"]
        );
        Notifier.unsupport("このサービスは終了いたしました。");
    },
    extraPages: [
        {
            kind: "image",
            url: "./res/miyagi/miyagi_yuusou.png"
        }
    ],
    onFinishPreview: () => {
    }
};
