// @ts-ignore
const DEBUG = __ENV__ === "development";
import "./styles/main.scss";
import * as megane from '../node_modules/meganets/dist/megane.js';
import { DigitalApplicationDefinition, ExtraPageDefinition, DigitalFormDefinition } from './digitalForm'
import { DigitalForm } from "./DigitalForm/DigitalForm";
import { DataBindManager, HTMLElementBindDataStorage, BindDataStorage, BindDataSource } from './bind';
import * as Bowser from "bowser/bundled";
import pako from "pako";

declare var __SDK_ENABLED__: boolean;
declare var __SDK_REFERER_ORIGIN__: string;
declare var __GOOGLE_MAP_API_KEY__: string;
declare var __BUILD_ID__: string;
declare var __QR_CODE_NAME_PREFIX__: string;
declare var __QR_CODE_IGNORE_NUMBER_OF_PAGES__: boolean;
declare var jsPDF;

const PDF_FILE_NAME = "書類";
const PDF_META_TITLE = "書類";

export class DigitalFormEditorApplication extends megane.SinglePageApplication {
    private readonly formData: DigitalApplicationDefinition;
    private _templates;
    private _currentViewController: megane.ViewController;
    private hasData: boolean = false;
    public savedData = null;
    public onShowFormView?: (formView: FormViewController) => void;

    constructor(formData, frame, templates) {
        super(frame);
        this.formData = formData;
        this._templates = templates;
        window.addEventListener('popstate', (e) => {
            this.showLeaveConfirmationMessageIfNeeded(location.hash);
        });
        window.addEventListener("load", () => {
            const builds = document.querySelectorAll('.debug-info');
            for (let i = 0; i < builds.length; i++) {
                // @ts-ignore
                builds[i].textContent = "build: " + __BUILD_ID__;
            }
            // app.onAppReady();

            //iOS13のiPad対応
            if (isiPad) {
                document.body.classList.add("tablet");
            } else {
                document.body.classList.add(browser.parsePlatform().type);
            }
        });
    }

    showLeaveConfirmationMessageIfNeeded(hash: string) {
        const token = hash.split("/");
        if (token[0] !== "#forms" && this.hasData) {
            if (isiOS) {
                setTimeout(() => {
                    Notifier.confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE, result => {
                        if (result) {
                            this.hasData = false;
                        } else {
                            window.history.forward();
                        }
                    });
                }, 0);
            } else {
                if (confirm(ARE_YOU_SURE_TO_LEAVE_MESSAGE)) {
                    this.hasData = false;
                } else {
                    window.history.forward();
                }
            }
        }
    }

    viewControllerForHash(hash) {
        window.scrollTo(0, 0);
        if (isIE11) {
            this.showLeaveConfirmationMessageIfNeeded(hash);
        }

        let token = hash.split("/");
        if (token[0] === "#" || token[0] === "") {
            try {
                let view = this._templates.get("acknowledgement-view");
                const viewController = new AcknowledgementViewController(view);
                viewController.onGotoFormButtonClick = (args) => {
                    this.savedData = args;
                    location.href = "#forms";
                };
                return viewController;
            } catch (e) {
                this.savedData = { _: "save" };
                location.href = "#forms";
                return;
            }
        } else if (token[0] === "#forms" || token[0] === "#qr") {
            if (!this.hasData) {
                if (!DEBUG && !this.savedData && token[0] !== "#qr") {
                    location.hash = "#";
                    return;
                }
                const view = this._templates.get("form-view");
                this._currentViewController = new FormViewController(this.formData, view, this.savedData);
                this.hasData = true;
            }
            const viewController: FormViewController = this._currentViewController;
            const page = (() => {
                if (token.length === 1) return 0;
                return parseInt(token[1]) - 1;
            })();
            if (viewController) {
                viewController.changeForm(page);
            }
            if (this.onShowFormView) {
                this.onShowFormView(viewController)
            }
            if (token.length === 3 && token[2] === "preview") {
                const index = token[1] + "-1";
                viewController.savePDF({
                    needsPreview: true,
                    indices: index,
                    qrEnabled: false
                });

                const timeout = 3000;
                let start = Date.now();
                const fn = () => {
                    const height = viewController.getPDFPreviewContentHeight();
                    if (height > 0) {
                        document.body.style.height = Math.ceil(height) + "px";
                    } else if (Date.now() - start < timeout) {
                        setTimeout(fn, 200);
                    }
                };
                setTimeout(fn, 200);
            }
            if (token[0] === "#qr") {
                viewController.showReadQRDialog("camera");
            }
            viewController.onFinishPreview = this.formData.onFinishPreview;
            return viewController;
        } else if (token[0] === "#require") {
            return new megane.ViewController(this._templates.get("requirement-view"));
        } else if (token[0] === "#others") {
            return new megane.ViewController(this._templates.get("others-view"));
        }
        return super.viewControllerForHash(hash);
    }
}

// @ts-ignore
class AcknowledgementViewController extends megane.ViewController {
    constructor(view) {
        super(view);
        const paymentTypeNew = view.querySelector("[name=payment-type]");
        paymentTypeNew.checked = true;
        const agree = view.querySelector(".agree-check");
        const nodebt = view.querySelector(".no_debt-check");
        const gotoFormButton = view.querySelector(".goto-form");
        gotoFormButton.addEventListener("click", (e) => {
            e.preventDefault();
            if (!agree.checked) {
                Notifier.show("入会申込にあたっては、事前に申し込みを行う児童クラブで説明を受ける必要があります");
                return;
            }
            if (!nodebt.checked) {
                Notifier.show("放課後児童クラブ利用料の滞納（未納）がある方は、入会申込できません。\n" +
                    "こども青少年課こども育成係(学童保育利用料担当)(電話：078-322-6392)までご相談ください。");
                return;
            }
            const gotoForm = () => {
                const paymentType = view.querySelector("[name=payment-type]:checked");
                const data = {
                    "payment_type_id": paymentType.id,
                };
                if (!isValidBrowser) {
                    data["application-unsupported_browser"] = "△サポート外ブラウザ使用: " + systemInfo;
                }
                this.onGotoFormButtonClick(data);
            };
            if (isValidBrowser) {
                gotoForm();
            } else {
                Notifier.unsupport(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                    if (ok) { gotoForm(); }
                });
            };
        });
    }

    didShowView(navigationController) {
        if (!isValidBrowser) {
            document.body.classList.add('unsupported');
            Notifier.unsupport(UNSUPPORTED_BROWSER_MESSAGE);
        }
        //iOS13 iPad対応
        if (isiPad) {
            document.body.classList.add("tablet");
        } else {
            document.body.classList.add(browser.parsePlatform().type);
        }
    }

    public onGotoFormButtonClick: (object) => void = function () { };
}

import { QRCodeReader, QRResult } from './ui/QRCodeReader';
import { DigitalFormDataSource } from "./DigitalForm/DigitalFormDataSource";
import { findFieldInput, getFieldInputData, getFieldInputKeys } from "./DigitalForm/Element/FieldInputContainer";
import { buildQRCodeDataURL, inflateQRCodePayload } from "./qrcode";
import { DigitalFormViewController } from "./DigitalForm/controller/DigitalFormViewController";

interface DynamicPageDefinition {
    kind: "dynamic";
    generate: () => Promise<HTMLIFrameElement>;
}

interface FormPageDefinition {
    kind: "form";
    index: PDFPreviewIndexSet;
}

type PageDefinition = FormPageDefinition | DynamicPageDefinition | ExtraPageDefinition;
type PageImage = HTMLCanvasElement | HTMLImageElement;

type SerializedPDFPreviewIndex = string | number;
type SerializedPDFPreviewIndexRepresentation = null | SerializedPDFPreviewIndex | SerializedPDFPreviewIndex[];

interface SavePDFInput {
    needsPreview?: boolean
    indices?: SerializedPDFPreviewIndexRepresentation
    qrEnabled?: boolean
}

// @ts-ignore
export class FormViewController extends megane.ViewController {
    private readonly view;
    private readonly paperFormFrames;
    public readonly digitalFormViewController: DigitalFormViewController;
    private formData: DigitalApplicationDefinition;
    private navButtons: HTMLElement[];
    private currentFormIndex?: number;
    private pdfPreviewWindow: PDFPreviewWindow;
    private pdfCanceled: boolean;
    private pdf;
    private qrURL: string;
    private qrName: string;
    private needsAccountTransfer: boolean;

    private nextFormButton;
    private prevFormButton;
    private changePageButton;

    private binder: DataBindManager;
    private isBinding: boolean = false;
    private currentScale: number = 1.0;
    private mainWindow: HTMLDivElement;
    private extraPages: ExtraPageDefinition[] = [];
    public onFinishPreview?: () => void;
    private bindInterceptor: BindDataStorage;
    private menuView: HTMLElement;
    private showMenuButton: HTMLButtonElement;

    constructor(formData: DigitalApplicationDefinition, view: HTMLElement, data) {
        super(view);
        this.view = view;
        this.formData = formData;
        const allForms = [];
        const applicationWindow = view.getElementsByClassName("application-window")[0];
        const mainWindow = view.getElementsByClassName("main-content")[0] as HTMLDivElement;
        this.mainWindow = mainWindow;
        this.view.addEventListener("touchmove", e => {
            if (this.isMenuVisible) {
                e.preventDefault();
            }
        });
        const nav = mainWindow.querySelector("nav");
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            const form = formData.forms[i];
            const rendering = formData.forms[i].rendering;
            const iframe = document.createElement("iframe");
            iframe.setAttribute("sandbox", "allow-modals allow-same-origin allow-scripts");
            iframe.setAttribute("style", "display: none;");
            iframe.setAttribute("data-num-pages", String(rendering.numberOfPages));
            iframe.src = rendering.path;
            applicationWindow.appendChild(iframe);
            allForms.push(iframe);

            const item = document.createElement("button");
            item.setAttribute("data-form", String(i + 1));
            item.setAttribute("role", "tab");
            item.setAttribute("aria-selected", i === 0 ? "true" : "false");
            if (i === 0) item.setAttribute("selected", "true");
            item.textContent = `${i + 1}. ${form.title}`;
            nav.appendChild(item);
        }
        this.paperFormFrames = allForms;

        this.needsAccountTransfer =
            !(data &&
                (data["payment_type_id"] === "bycache" ||
                    data["payment_type_id"] === "no"));
        if (!this.needsAccountTransfer) {
            this.paperFormFrames.splice(2, 1);
        }

        const digitalFormView = view.getElementsByClassName("main-content")[0] as HTMLElement;
        this.digitalFormViewController = new DigitalFormViewController(formData, digitalFormView, data);
        const forms = this.digitalFormViewController.digitalForms;
        forms.forEach(form => {
            form.onUpdateFieldInput = () => {
                this.rebind();
            }
        });

        const extraPages = formData.extraPages;
        if (extraPages) {
            this.extraPages = extraPages;
        }

        if (DEBUG) {
            const footer = view.getElementsByTagName("footer")[0];

            const exportButton = document.createElement("button");
            exportButton.setAttribute('debug', '');
            exportButton.textContent = "エクスポート";
            exportButton.addEventListener("click", () => {
                const sourceElement = document.createElement("textarea");
                document.body.appendChild(sourceElement);
                const clone = this.paperFormFrames[this.currentFormIndex].contentWindow.document.documentElement.cloneNode(true);
                const clones = clone.querySelectorAll("[data-mark=clone]");
                for (let i = 0, l = clones.length; i < l; i++) {
                    clones[i].parentNode.removeChild(clones[i]);
                }
                const htmlContent = '<!doctype html>\n' + clone.outerHTML;
                sourceElement.textContent = htmlContent.replace(/ style=""/g, "");
                const range = document.createRange();
                range.selectNodeContents(sourceElement);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                sourceElement.setSelectionRange(0, sourceElement.textContent.length);

                if (document.execCommand("copy", false, null)) {
                    alert("クリップボードにコピーしました");
                }
                document.body.removeChild(sourceElement);
            });
            footer.appendChild(exportButton);
        }

        const templates = document.getElementById("template");
        const previewWindow = templates.getElementsByClassName("pdf-preview-window")[0].cloneNode(true);
        this.pdfPreviewWindow = new PDFPreviewWindow(previewWindow, this.view);
        this.pdfPreviewWindow.onSave = () => {
            if (this.pdf) {
                if (isiOS) {
                    const result = this.pdf.output("blob", { filename: this.getPDFFilename() });
                    window.open(URL.createObjectURL(result));
                } else {
                    this.pdf.save(this.getPDFFilename());
                }
            } else if (this.qrURL) {
                const link = document.createElement("a");
                link.href = this.qrURL;
                link.download = this.qrName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };
        this.pdfPreviewWindow.onCancel = () => {
            this.pdfCanceled = true;
            if (!this.pdfPreviewWindow.isHidden()) {
                this.pdfPreviewWindow.hide();
            }
        };
        this.pdfPreviewWindow.onClose = () => {
            if (this.onFinishPreview) {
                this.onFinishPreview();
            }
        };
        this.pdf = null;

        const confirmBrowserSupport = (callback: () => void) => {
            if (isValidBrowser) {
                callback();
            } else {
                Notifier.unsupport(UNSUPPORTED_BROWSER_MESSAGE, (ok) => {
                    if (ok) { callback(); }
                });
            }
        };

        const showMenuButton = view.querySelector<HTMLButtonElement>(".show-menu");
        if (showMenuButton) {
            showMenuButton.addEventListener("click", () => {
                this.isMenuVisible = !this.isMenuVisible;
            });
            this.showMenuButton = showMenuButton;
            this.menuView = view.querySelector(".menu");
            const as = Array.from(this.menuView.querySelectorAll("a"));
            as.forEach(a => a.addEventListener("click", () => {
                this.isMenuVisible = false;
            }));
        }

        let onSavePDF: (form: FormViewController) => void;
        Array.prototype.slice.call(view.querySelectorAll(".save-pdf,.save-pdf-noqr")).forEach((elm) => {
            elm.addEventListener("click", (e) => {
                confirmBrowserSupport(() => {
                    if (onSavePDF) {
                        onSavePDF(this);
                    }
                    this.savePDF({
                        needsPreview: true,
                        qrEnabled: elm.classList.contains("save-pdf")
                    })
                });
            });
        });

        const readQRButton = view.querySelector(".read-qr");
        if (readQRButton) {
            readQRButton.addEventListener("click", async () => {
                this.readQRCode("camera");
            });
        }

        const readQRImageButton = view.querySelector(".read-qr-image");
        if (readQRImageButton) {
            readQRImageButton.addEventListener("click", () => {
                this.readQRCode("image");
            });
        }

        const readQRCameraButton = view.querySelector(".read-qr-camera");
        if (readQRCameraButton) {
            readQRCameraButton.addEventListener("click", () => {
                this.readQRCode("camera");
            })
        }

        let onSaveQRCode: (form: FormViewController) => void;
        const showQRButton = view.querySelector(".show-qr");
        if (showQRButton) {
            showQRButton.addEventListener("click", () => {
                confirmBrowserSupport(() => {
                    if (onSaveQRCode) {
                        onSaveQRCode(this);
                    }
                    this.saveQRCode();
                });
            });
        }

        const showQRSingleButtons = Array.from(view.querySelectorAll<HTMLButtonElement>(".show-qr-single"));
        if (showQRSingleButtons) {
            showQRSingleButtons.forEach(button => button.addEventListener("click", () => {
                confirmBrowserSupport(() => {
                    this.saveQRCodeAsSingleImage(new PDFPreviewIndexSet(this.currentFormIndex + 1, 1));
                });
            }));
        }

        const printQRButton = view.querySelector(".print-qr");
        if (printQRButton) {
            printQRButton.addEventListener("click", (e) => {
                this.printQRCode();
            });
        }

        Array.prototype.slice.call(view.querySelectorAll(".print-pdf,.print-pdf-noqr")).forEach((elm) => {
            elm.addEventListener("click", (e) => {
                const qr = elm.classList.contains("print-pdf");
                this.printPDF(qr);
            });
        });

        const showHowtoDownloadButton = view.querySelector(".show-howto-download");
        if (showHowtoDownloadButton) {
            showHowtoDownloadButton.addEventListener("click", (e) => {
                let message = '';
                if(browserInfo.name === "Safari"){
                    if(isiPhone)
                    {
                        Notifier.howto(HOWTO_DOWNLOAD_IPHONE);
                    }
                    else if(isiPad)
                    {
                        Notifier.howto(HOWTO_DOWNLOAD_IPAD);
                    }
                    else if(osInfo.name === "macOS")
                    {
                        Notifier.howto(HOWTO_DOWNLOAD_MACOS);
                    }
                } 
                else if(osInfo.name === 'Windows')
                {
                    if(browserInfo.name === 'Chrome')
                    {
                        Notifier.howto(HOWTO_DOWNLOAD_WIN10CHROME);
                    } 
                    else if(browserInfo.name === 'Microsoft Edge')
                    {
                        Notifier.howto(HOWTO_DOWNLOAD_WIN10EDGE);
                    }
                } 
                else if(isAndroid)
                {
                    Notifier.howto(HOWTO_DOWNLOAD_ANDROID);
                } 
                else
                {
                    Notifier.unsupport(UNSUPPORTED_BROWSER_MESSAGE);
                }
            });
        }

        this.nextFormButton = view.querySelector(".next-form");
        this.nextFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex + 1) % this.paperFormFrames.length + 1);
        });

        this.prevFormButton = view.querySelector(".prev-form");
        this.prevFormButton.addEventListener("click", (e) => {
            location.hash = '#forms/' + ((this.currentFormIndex - 1) % this.paperFormFrames.length + 1);
        });

        this.changePageButton = view.querySelector(".change-page");
        if (this.changePageButton) {
            this.changePageButton.addEventListener("click", (e) => {
                if (this.isBinding) {
                    return;
                }
                const iframe = this.paperFormFrames[this.currentFormIndex];
                let page = +iframe.getAttribute("data-page");
                let num = +iframe.getAttribute("data-num-pages");
                this.changePage(page >= num ? 1 : (page + 1));
            });
        }

        // Navigation buton
        const buttons = view.querySelectorAll<HTMLButtonElement>("nav > button");
        this.navButtons = [];
        for (let i = 0; i < buttons.length; ++i) {
            const button = buttons[i];
            if (this.paperFormFrames.indexOf(allForms[i]) === -1) {
                button.style.display = "none";
            } else {
                button.addEventListener("click", () => {
                    location.hash = '#forms/' + button.getAttribute("data-form");
                });
                this.navButtons.push(button);
            }
        }
        //雑に拡大縮小を実装した
        //iframeにtransformかけるとめんどくさいと思い中身を直接transformかける野蛮方式
        // 課題1. フォームをきりかえるたびにscaleをリセットするか現状のscaleにする
        // 課題2. 初回起動時にiframeの幅に合わせて拡大縮小する
        const zoom_button = view.querySelector("#zoom");
        zoom_button.addEventListener('click', (e) => {
            if (this.currentScale < 5.0) {
                this.currentScale += 0.1;
                this.updateScale();
            }
        });
        const shrink_button = view.querySelector("#shrink");
        shrink_button.addEventListener('click', (e) => {
            if (this.currentScale > 0.1) {
                this.currentScale -= 0.1;
                this.updateScale();
            }
        });

        if (__SDK_ENABLED__) {
            const formForId = (formId: string): DigitalForm => {
                const forms = this.digitalFormViewController.digitalForms;
                for (let i = 0, l = forms.length; i < l; i++) {
                    const form = forms[i];
                    if (form.id === formId) {
                        return form;
                    }
                }
                throw new Error(`form not found: ${formId}`);
            };

            const getInput = (formId: string, key: string) => {
                const form = formForId(formId);
                const input = findFieldInput(form, key);
                if (!input) {
                    throw new Error(`input not found: ${key}`);
                }
                return input;
            }

            const getValue = (formId: string, key: string) => {
                try {
                    return getInput(formId, key).value;
                } catch (e) {
                }
                return null;
            };

            const postMessage = (data: any) => {
                parent.postMessage(data, __SDK_REFERER_ORIGIN__);
            };

            const resolve = (id: number, value: any) => {
                postMessage({
                    type: "resolve",
                    id,
                    value
                });
            };

            const extractInputs = (form: DigitalFormDefinition) => {
                const inputs = form.boxes.flatMap(box => {
                    return box.elements
                });
                const filtered = inputs.filter(input => ["number", "text", "radio", "checkbox", "select", "textarea"].includes(input.kind));
                return filtered.map(input => ({
                    id: input.id,
                    kind: input.kind,
                    title: input.title
                }));
            };

            window.addEventListener("message", e => {
                if (e.data.type === "formSetValue") {
                    try {
                        getInput(e.data.formId, e.data.key).value = e.data.value;
                        resolve(e.data.id, e.data.value);
                    } catch (e) {
                        resolve(e.data.id, null);
                    }
                } else if (e.data.type === "getRawValue") {
                    resolve(e.data.id, getValue(e.data.formId, e.data.key));
                } else if (e.data.type === "getFormData") {
                    resolve(e.data.id, formForId(e.data.formId).data);
                } else if (e.data.type === "setFormData") {
                    try {
                        formForId(e.data.formId).data = e.data.data;
                        resolve(e.data.id, e.data.data);
                    } catch (e) {
                        resolve(e.data.id, null);
                    }
                }
            });

            // const cache = {};
            this.bindInterceptor = {
                update: (src: BindDataSource, manager: DataBindManager) => {
                    // const key = src.key;
                    // const value = src.value;
                    // if (cache[key] === value) return;
                    // console.log("update", src.);
                    // parent.postMessage({
                    //     type: "formValueChange",
                    //     key,
                    //     value
                    // }, __SDK_REFERER_ORIGIN__);
                    // cache[key] = value;
                }
            }

            postMessage({
                type: "loadForms",
                forms: formData.forms.map(form => ({
                    id: form.id,
                    title: form.title,
                    inputs: extractInputs(form)
                })),
                roleMap: formData.roleMap
            });

            onSavePDF = () => {
                postMessage({
                    type: "savePDF"
                });
            };

            onSaveQRCode = () => {
                postMessage({
                    type: "saveQRCode"
                });
            };
        }
    }

    get isMenuVisible(): boolean {
        return this.menuView.classList.contains("show");
    }

    set isMenuVisible(visible: boolean) {
        if (this.isMenuVisible === visible) return;
        if (!this.menuView) throw new Error("menu view was not found");
        if (visible) {
            this.showMenu();
        } else {
            this.hideMenu();
        }
    }

    private showMenu() {
        this.menuView.classList.add("show");
        if (this.showMenuButton) {
            this.showMenuButton.classList.add("show");
        }
    }

    private hideMenu() {
        if (!this.menuView) throw new Error("menu view was not found");
        this.menuView.classList.remove("show");
        if (this.showMenuButton) {
            this.showMenuButton.classList.remove("show");
        }
    }

    updateScale() {
        this.paperFormFrames[this.currentFormIndex].contentWindow.document.body.style.transform = "scale( " + this.currentScale + " )";
    }

    async waitForIframePromise(iframe: HTMLIFrameElement): Promise<void> {
        return new Promise((resolve, reject) => {
            const fn = () => {
                iframe.removeEventListener("load", fn);
                resolve();
            };
            iframe.addEventListener("load", fn);
        });
    }

    waitForIframe(iframe, callback) {
        const fn = () => {
            iframe.removeEventListener("load", fn);
            callback(iframe);
        };
        iframe.addEventListener("load", fn);
    }

    public rebind(paperFormFrame?, digitalForm?: DigitalForm) {
        const iframe = paperFormFrame || this.paperFormFrames[this.currentFormIndex];
        digitalForm = digitalForm || this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.bind(this.binder, iframe, digitalForm);
    }

    private bind(binder: DataBindManager, iframe: HTMLIFrameElement, digitalForm: DigitalForm) {
        const keys = getFieldInputKeys(digitalForm);
        keys.forEach(key => binder.registerSource(new DigitalFormDataSource(digitalForm, key)));
        const errors = [];
        const spans = iframe.contentWindow.document.querySelectorAll<HTMLElement>("[data-bind]");
        for (let i = 0, l = spans.length; i < l; i++) {
            const dst = spans[i];
            const bind = dst.getAttribute('data-bind');
            if (bind) {
                try {
                    binder.registerDestination({
                        key: bind,
                        storage: new HTMLElementBindDataStorage(dst)
                    });
                    if (this.bindInterceptor) {
                        binder.registerDestination({
                            key: bind,
                            storage: this.bindInterceptor
                        });
                    }
                } catch (e) {
                    errors.push("data-id not found: " + bind);
                }
            }
        }
        if (errors.length > 0) console.error('bind error', errors);
    }

    public rebindAll() {
        const iframe = this.paperFormFrames[this.currentFormIndex];
        const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.bindFields(iframe, digitalForm);
    }

    private createBinder() {
        return new DataBindManager(__GOOGLE_MAP_API_KEY__);
    }

    bindFields(paperFormFrame, digitalForm) {
        this.waitForIframe(paperFormFrame, () => {
            if (this.binder) {
                this.binder.clearAllSources();
                this.binder.clearAllDestinations();
            }
            this.binder = this.createBinder();
            this.bind(this.binder, paperFormFrame, digitalForm);
        });
    }

    showReadQRDialog(mode: "camera" | "image", message?: string) {
        Notifier.confirm2(message || "QRコードから復元しますか？", readQR => {
            if (readQR) {
                this.readQRCode(mode);
            }
        });
    }

    decodeQRCode(result: QRResult) {
        const formData = this.formData;
        const forms = this.digitalFormViewController.digitalForms;
        const data = ((raw) => {
            if (raw.charAt(0) === "{") {
                return raw;
            }
            return pako.inflate(raw, {to: "string"});
        })(result.payload);
        const payload = JSON.parse(data);
        for (let i = 0, l = formData.forms.length; i < l; i++) {
            if (formData.forms[i].title === payload.title) {
                this.changeForm(i);
                forms[i].data = inflateQRCodePayload(Object.keys(forms[i].data), payload.data);
                return true;
            }
        }
        return false;
    }

    async readQRCode(mode: "camera" | "image") {
        const qrreader = new QRCodeReader();
        try {
            const result = await (() => {
                if (mode === "camera") {
                    return qrreader.startScanning();
                } else if(mode === "image") {
                    return qrreader.readFromImage();
                }
            })();
            if (result) {
                if (this.decodeQRCode(result)) {
                    this.showReadQRDialog(mode, "読み込みが完了しました。引き続き読み込みますか？");
                } else {
                    this.showReadQRDialog(mode, "QRコードからデータを復元できませんでした。選択した申請書の名称と、QRコードの名称が一致していない可能性があります。別のQRコードを読み込む場合は、引き続き読み込みを行うことができます。「OK」ボタンを押してください。");
                }
            } else {
                this.showReadQRDialog(mode, "QRコードが見つかりませんでした。引き続き読み込みますか？");
            }
        } catch (e) {
            console.log(e);
        }
        return null;
    }

    private async loadHTML(url: string): Promise<HTMLIFrameElement> {
        return new Promise(resolve => {
            const iframe = document.createElement("iframe");
            const callback = () => {
                resolve(iframe);
                iframe.removeEventListener("load", callback);
            };
            iframe.addEventListener("load", callback);
            iframe.src = url;
            document.body.appendChild(iframe);
        });
    }

    async renderImageFromURL(url: string): Promise<HTMLImageElement> {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => {
                image.onload = null;
                image.width > image.height ? image.setAttribute("style", "width:85vw;height:auto;") : image.setAttribute("style", "width:60vw;height:auto;");
                resolve(image);
            };
            image.onerror = (e) => {
                reject(e);
            };
            image.src = url;
        })
    }

    async renderPage(source: PageDefinition, width?: number, height?: number): Promise<PageImage> {
        if (source.kind === "form") {
            const iframe = await this.cloneFormIFreame(source.index);
            return await this.iframe2canvas(iframe, width, height);
        } else if (source.kind === "dynamic") {
            const frame = await source.generate();
            return await this.iframe2canvas(frame, width, height);
        } else if (source.kind === "html") {
            const frame = await this.loadHTML(source.url);
            return await this.iframe2canvas(frame, width, height);
        } else if (source.kind === "image") {
            return await this.renderImageFromURL(source.url);
        }
        throw new Error(`unknown kind: "${source.kind}"`);
    }

    async renderPageAsImage(source: PageDefinition): Promise<HTMLImageElement> {
        const imageSource = await this.renderPage(source);
        if (imageSource.constructor === HTMLCanvasElement) {
            return await this.renderImageFromURL(imageSource.toDataURL("image/png"));
        } else if (imageSource.constructor === HTMLImageElement) {
            return imageSource;
        }
        throw new Error("this should not happen");
    }

    async renderPagesWithProgress(sources: PageDefinition[]): Promise<PageImage[]> {
        const pages: PageImage[] = [];
        const numPages = sources.length;

        this.pdfCanceled = false;
        this.pdfPreviewWindow.showProgress();

        try {

            for (let i = 0; i < numPages && !this.pdfCanceled; i++) {
                this.pdfPreviewWindow.setProgress(pages.length, numPages);
                pages.push(await this.renderPage(sources[i]));
            }

        } catch (e) {
            console.log(e);
            alert("プレビューの作成中に問題が発生しました。もう一度実行してください。 (" + e + ")");
            this.pdfPreviewWindow.hide();
        }

        return pages;
    }

    buildPDFObject(pages: PageImage[]): any {
        const doc = new jsPDF({
            orientation: "portrait",
            unit: "in",
            format: "a4",
        });

        doc.setProperties({
            title: this.formData.pdfMetaTitle || PDF_META_TITLE,
            creator: __BUILD_ID__,
        });

        pages.forEach((page, i) => {
            if (page.width <= page.height) {
                doc.addImage(page, "JPEG", 0, 0, 21.0 / 2.54, 29.7 / 2.54);
            } else {
                // rotate 90deg
                doc.addImage(page, "JPEG", 0, -21.0 / 2.54, 29.7 / 2.54, 21.0 / 2.54, null, null, 270);
            }
            if (i !== pages.length - 1) {
                doc.addPage();
            }
        });

        return doc;
    }

    showPreview(mode: string, pages: PageImage[]) {
        pages.forEach((page) => {
            const orientation = page.width <= page.height ?
                PDFPreviewOrientation.Portrait : PDFPreviewOrientation.Landscape;
            this.pdfPreviewWindow.appendPreview(page, orientation);
        });
        this.pdfPreviewWindow.showPreview(mode);
    }

    downloadPDF(pdf: any) {
        this.pdfPreviewWindow.hide();
        if (isiOS) {
            const result = pdf.output("blob", { filename: this.getPDFFilename() });
            window.open(URL.createObjectURL(result));
        } else {
            pdf.save(this.getPDFFilename());
        }
    }

    preparePDF(indices?: SerializedPDFPreviewIndexRepresentation, qrEnabled: boolean = true): PageDefinition[] {
        const sourceIndices = this.parsePDFPreviewIndices(indices);
        if (sourceIndices.length === 0) {
            alert("無効なフォーム番号が指定されました。 (" + indices + ")");
            return;
        }
        const sources = sourceIndices.flatMap(index => {
            const forms:PageDefinition[]  = [
                { kind: "form" as const, index }
            ];
            const extraPages = this.formData.forms[index.form - 1].rendering.extraPages;
            if (extraPages) {
                forms.push(...extraPages);
            }
            return forms;
        });
        const pages = sources.concat(this.extraPages);
        if (!qrEnabled) {
            return pages;
        } else {
            const qrSourceIndices = __QR_CODE_IGNORE_NUMBER_OF_PAGES__ ? this.removeDuplicatedFormFromPDFPreviewIndices(sourceIndices) : sourceIndices;
            return pages.concat(this.buildQRPageDefinition(qrSourceIndices));
        }
    }

    // PDF
    async savePDF({ needsPreview = true, indices = null, qrEnabled}: SavePDFInput) {
        this.qrURL = null;
        this.qrName = null;
        this.pdf = null;

        const output = this.preparePDF(indices, qrEnabled);
        const pages = await this.renderPagesWithProgress(output);
        this.pdf = this.buildPDFObject(pages);

        if (needsPreview) {
            this.showPreview('PDF', pages);
        } else {
            this.downloadPDF(this.pdf);
        }
    }

    async printPDF(qrEnabled: boolean) {
        this.qrURL = null;
        this.qrName = null;
        this.pdf = null;

        const output = this.preparePDF(null, qrEnabled);
        var pages = await this.renderPagesWithProgress(output)
        this.pdfPreviewWindow.isSaveButtonVisible = false;
        this.showPreview('PDF', pages);

        setTimeout(() => {
            this.pdfPreviewWindow.print();
        }, 0);
    }

    getFormDefinitionAtIndex(index: PDFPreviewIndexSet): DigitalFormDefinition {
        return this.formData.forms[index.form - 1];
    }

    buildSingleQRPageDefinition(index: PDFPreviewIndexSet, qrTemplate?: string): DynamicPageDefinition {
        const formDefinition = this.getFormDefinitionAtIndex(index);
        const title = formDefinition.title;
        const qr_template = qrTemplate || formDefinition.qr_template;
        const form = this.digitalFormViewController.digitalForms[index.form - 1];
        return {
            kind: "dynamic",
            generate: () => {
                return new Promise((resolve, reject) => {
                    const iframe = document.createElement("iframe");
                    // iframe.style.display = "none";
                    iframe.addEventListener("load", async () => {
                        // iframe.contentDocument.querySelector("[data-name='title']").textContent = "千葉市" + title + " 必要事項";
                        const qrcodeArea = iframe.contentDocument.querySelector<HTMLImageElement>("[data-name='qrcode']");
                        if (qrcodeArea) {
                            qrcodeArea.src = await buildQRCodeDataURL(title, form.data);
                        }
                        const qrcodeTitle = iframe.contentDocument.querySelector<HTMLImageElement>("[data-name='qrcode_title']");
                        if (qrcodeTitle){
                            qrcodeTitle.textContent = title;
                        }
                        let date = new Date();
                        const today = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                        const createdDateArea = iframe.contentDocument.querySelector("[data-name='pdf_created_date']");
                        if (createdDateArea) {
                            createdDateArea.textContent = today;
                            resolve(iframe);
                        }
                    });
                    iframe.src = qr_template;
                    document.body.appendChild(iframe);
                });
            }
        };
    }

    buildQRPageDefinition(sourceIndices: PDFPreviewIndexSet[]): DynamicPageDefinition[] {
        return sourceIndices.map(index => this.buildSingleQRPageDefinition(index));
    }

    prepareQRCode(indices?: SerializedPDFPreviewIndexRepresentation): PageDefinition[] {
        const sourceIndices = this.parsePDFPreviewIndices(indices);
        if (sourceIndices.length === 0) {
            alert("無効なフォーム番号が指定されました。 (" + indices + ")");
            return;
        }
        const qrSourceIndices = __QR_CODE_IGNORE_NUMBER_OF_PAGES__ ? this.removeDuplicatedFormFromPDFPreviewIndices(sourceIndices) : sourceIndices;
        const output = this.buildQRPageDefinition(qrSourceIndices);
        return output;
    }

    async saveQRCode(needsPreview: boolean = true, indices: SerializedPDFPreviewIndexRepresentation = null) {
        this.qrURL = null;
        this.qrName = null;
        this.pdf = null;

        const output = this.prepareQRCode(indices);
        const pages = await this.renderPagesWithProgress(output);
        this.pdf = this.buildPDFObject(pages);

        if (needsPreview) {
            this.showPreview('QR', pages);
        } else {
            this.downloadPDF(this.pdf);
        }
    }

    async printQRCode() {
        this.qrURL = null;
        this.qrName = null;
        this.pdf = null;

        const output = this.prepareQRCode();
        var pages = await this.renderPagesWithProgress(output)
        this.pdfPreviewWindow.isSaveButtonVisible = false;
        this.showPreview('QR', pages);

        setTimeout(() => {
            this.pdfPreviewWindow.print();
        }, 0);
    }

    async saveQRCodeAsSingleImage(index: PDFPreviewIndexSet) {
        const definition = this.buildSingleQRPageDefinition(index, "./qrcode_download.html");
        const formDefinition = this.getFormDefinitionAtIndex(index);
        this.pdf = null;
        this.pdfCanceled = false;
        this.pdfPreviewWindow.isSaveButtonVisible = !isMobile;
        this.pdfPreviewWindow.showProgress();
        this.pdfPreviewWindow.setProgress(0, 2);
        const image = (await this.renderPage(definition)) as HTMLCanvasElement;
        this.pdfPreviewWindow.setProgress(1, 2);
        this.qrURL = image.toDataURL("image/png");
        this.qrName = `${__QR_CODE_NAME_PREFIX__}${formDefinition.title}.png`;
        this.pdfPreviewWindow.setProgress(2, 2);
        this.pdfPreviewWindow.appendPreview(image, PDFPreviewOrientation.Portrait);
        if (isMobile) {
            const downloader = document.getElementById("template").querySelector(".qr-code-downloader").cloneNode(true) as HTMLElement;
            downloader.querySelector(".qr-code-name").textContent = this.formData.forms[index.form - 1].title;
            const link = downloader.querySelector<HTMLAnchorElement>(".download-link");
            link.href = "javascript:void(0);";
            const blob = await new Promise(resolve => image.toBlob(resolve));
            link.addEventListener("click", () => {
                window.open(URL.createObjectURL(blob));
            });
            this.pdfPreviewWindow.appendPreview(downloader, PDFPreviewOrientation.Portrait);
        }
        this.pdfPreviewWindow.showPreview("QR");
    }

    parsePDFPreviewIndices(indices?: SerializedPDFPreviewIndexRepresentation): PDFPreviewIndexSet[] {
        if (indices === null || indices === undefined) {
            return this.paperFormFrames.reduce((result, elm, i) => {
                const form = i + 1;
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }, []);
        }
        if (Array.isArray(indices)) {
            return indices.reduce((result, index) => {
                if (index !== null && index !== undefined) {
                    const tmp = this.parsePDFPreviewIndices(index);
                    result = result.concat(tmp);
                }
                return result;
            }, []);
        }
        const form = +indices;
        if (!isNaN(form)) {
            if (form >= 1 && form <= this.paperFormFrames.length) {
                const elm = this.paperFormFrames[form - 1];
                const result = [];
                const num = +elm.getAttribute("data-num-pages");
                for (let page = 1; page <= num; ++page) {
                    result.push(new PDFPreviewIndexSet(form, page));
                }
                return result;
            }
            return [];
        }
        if (typeof (indices) === "string") {
            const pair = indices.split("-");
            if (pair.length === 2) {
                const form = +pair[0];
                const page = +pair[1];
                if (!isNaN(page) && !isNaN(form) && form >= 1 && form <= this.paperFormFrames.length) {
                    const elm = this.paperFormFrames[form - 1];
                    const num = +elm.getAttribute("data-num-pages");
                    if (page >= 1 && page <= num) {
                        return [new PDFPreviewIndexSet(form, page)];
                    }
                }
            }
            return [];
        }
        return [];
    }

    removeDuplicatedFormFromPDFPreviewIndices(indices: PDFPreviewIndexSet[]) {
        const map = {};
        return indices.filter((index) => {
            if (map[index.form]) {
                return false;
            } else {
                map[index.form] = true;
                return true;
            }
        });
    }

    getPDFFilename() {
        const pdfFileName = this.formData.pdfFileName || PDF_FILE_NAME;
        return `${pdfFileName}${DEBUG ? '-' + Date.now() : ''}.pdf`;
    }

    getPDFPreviewContentHeight() {
        return this.pdfPreviewWindow.getContentHeight();
    }

    isHorizontalContent(iframe: HTMLIFrameElement) {
        return iframe.contentWindow.document.querySelector('body > .horizontal') !== null;
    }

    private async cloneFormIFreame(index: PDFPreviewIndexSet): Promise<HTMLIFrameElement> {
        const width = 1240;
        const height = 1754;
        const iframe = this.paperFormFrames[index.form - 1];
        return new Promise((resolve, reject) => {
            const form = index.form;
            const page = index.page;
            const container = this.view.querySelector(".hidden");
            const cloned = <HTMLIFrameElement>iframe.cloneNode(true);
            container.appendChild(cloned);
            cloned.style.display = "block";
            cloned.style.width = width + "px",
                cloned.style.height = height + "px";
            cloned.setAttribute("src", iframe.src);

            this.waitForIframe(cloned, () => {
                // apply input
                cloned.contentWindow.document.body.innerHTML = iframe.contentWindow.document.body.innerHTML;
                const binder = this.createBinder();
                this.bind(binder, cloned, this.digitalFormViewController.digitalForms[form - 1]);
                this.changePage(page, cloned);
                resolve(cloned);
            });
        });
    }

    async iframe2canvas(cloned: HTMLIFrameElement, width: number = 1240, height: number = 1754) {
        const horizontal = this.isHorizontalContent(cloned);
        const canvas = await html2canvas(cloned.contentWindow.document.body, {
            width: horizontal ? height : width,
            height: horizontal ? width : height,
            scale: 1,
            backgroundColor: "#ffffff",
            useCORS: true,
            onclone: isiPhone ? this.onIFrameClone : null,
            logging: DEBUG,
        });
        if (cloned.parentNode) {
            cloned.parentNode.removeChild(cloned);
        }
        canvas.style.width = horizontal ? "85vw" : "60vw";
        canvas.style.height = null;
        return canvas;
    }

    onIFrameClone(doc) {
        Array.prototype.slice.call(doc.querySelectorAll("[data-fs]")).forEach((elm) => {
            for (let i = 5; i <= 18; ++i) {
                elm.classList.remove("pt" + i);
            }
            var fontSize = elm.getAttribute("data-fs");
            if (fontSize.startsWith("pt")) {
                elm.classList.add(fontSize);
            } else {
                const ppi = 150;
                const zoom = 0.87;
                var point = +fontSize;
                if (!isNaN(point)) {
                    elm.style.fontSize = `calc(${point} / 72 * ${ppi} * ${zoom} * 0.08vw)`;
                }
            }
        });
    }

    changeForm(formIndex) {
        if (this.currentFormIndex === formIndex) {
            this.rebindAll();
            return;
        }
        if (this.currentFormIndex !== undefined) {
            const lastFrame = this.paperFormFrames[this.currentFormIndex];
            lastFrame.style.display = "none";
            this.digitalFormViewController.selectedIndex = -1;
        }
        this.currentFormIndex = formIndex;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        iframe.style.display = "block";
        const digitalForm = this.digitalFormViewController.digitalForms[this.currentFormIndex];
        this.digitalFormViewController.selectedIndex = this.currentFormIndex;
        this.bindFields(iframe, digitalForm);
        this.changePageSafe(1);
        this.updateButtonState();
    }

    changePageSafe(pageNumber: number) {
        const paperFormFrame = this.paperFormFrames[this.currentFormIndex];
        const formIndex = this.currentFormIndex;
        const fn = () => {
            paperFormFrame.removeEventListener("load", fn);
            if (this.currentFormIndex === formIndex) {
                this.changePage(pageNumber)
            }
        };
        paperFormFrame.addEventListener("load", fn);
    }

    changePage(pageNumber: number, iframe: HTMLIFrameElement = null) {
        iframe = iframe || this.paperFormFrames[this.currentFormIndex];
        iframe.setAttribute("data-page", String(pageNumber));
        for (let i = 1; i <= 3; ++i) {
            const hidden = i !== +pageNumber;
            const elms = iframe.contentWindow.document.getElementsByClassName("only-" + i);
            Array.prototype.forEach.call(elms, (elm) => {
                if (pageNumber === -1) {
                    elm.removeAttribute("data-display");
                    elm.style.display = null;
                    elm.removeAttribute("style");
                } else {
                    if (!elm.getAttribute("data-display")) {
                        var style = document.defaultView.getComputedStyle(elm);
                        elm.setAttribute("data-display", style.display);
                    }
                    elm.style.display = hidden ? "none" : elm.getAttribute("data-display");
                }
            });
        }
        //決め打ちにしてしまう
        let iframe_main_class = iframe.contentWindow.document.querySelector('main').classList;
        let iframe_scroll_width = 1240;
        //tsの中だからes6使えるのかもしれんけどよくわからんのでcontains使わないでこんな感じに
        if (iframe_main_class[0] === 'horizontal') {
            iframe_scroll_width = 1754;
        }
        iframe.contentWindow.document.querySelector("main").setAttribute("data-page", String(pageNumber));
        // あえて一瞬待つことで上の変更を反映させる
        setTimeout(() => {
            this.currentScale = this.mainWindow.offsetLeft / iframe_scroll_width;
            this.updateScale();
            const tabButton = document.querySelector('.generated>nav button[selected]');
            if (tabButton) {
                tabButton.scrollIntoView({ block: "end", inline: "end" });
            }
        });
    }

    updateButtonState() {
        this.nextFormButton.disabled = this.isBinding || this.currentFormIndex === this.paperFormFrames.length - 1;
        this.prevFormButton.disabled = this.isBinding || this.currentFormIndex === 0;
        const iframe = this.paperFormFrames[this.currentFormIndex];
        let num = +iframe.getAttribute("data-num-pages");
        if (this.changePageButton) {
            this.changePageButton.disabled = this.isBinding || num <= 1;
        }
        for (let j = 0; j < this.navButtons.length; ++j) {
            const button = this.navButtons[j];
            if (j === this.currentFormIndex) {
                button.setAttribute("selected", "");
                button.setAttribute("aria-selected", "true");
            } else {
                button.removeAttribute("selected");
                button.setAttribute("aria-selected", "false");
            }
        }
    }

    didShowView(navigationController) {
        if (!isValidBrowser || typeof isValidBrowser === "undefined") {
            Notifier.unsupport(UNSUPPORTED_BROWSER_MESSAGE);
        }
    }
}

class PDFPreviewIndexSet {
    public form: number;
    public page: number;

    constructor(form: number, page: number) {
        this.form = form;
        this.page = page;
    }
}

const PDFPreviewOrientation = {
    Portrait: 'portrait',
    Landscape: 'landscape'
} as const;
type PDFPreviewOrientation = typeof PDFPreviewOrientation[keyof typeof PDFPreviewOrientation];

class PDFPreviewWindow {
    private view: HTMLElement;
    private container: HTMLElement;
    private progressView: HTMLElement;
    private progressBar: HTMLElement;
    private previewView: HTMLElement;
    private previewFrame: HTMLIFrameElement;
    private readonly saveButton: HTMLButtonElement;
    public onSave: Function;
    public onCancel: Function;
    public onClose: Function;

    constructor(view, container) {
        this.view = view;
        this.container = container;
        this.progressView = this.view.getElementsByClassName("pdf-progress")[0] as HTMLElement;
        this.progressBar = this.progressView.getElementsByClassName("pdf-progress-bar")[0] as HTMLElement;
        this.previewView = this.view.getElementsByClassName("pdf-preview")[0] as HTMLElement;
        this.previewFrame = this.previewView.getElementsByTagName("IFRAME")[0] as HTMLIFrameElement;

        if (isiOS) {
            this.view.addEventListener("touchmove", this.preventScroll, false);
        }

        const cancelButton = this.view.getElementsByClassName("pdf-progress-cancel")[0];
        cancelButton.addEventListener("click", (e) => {
            if (this.onCancel) {
                this.onCancel();
            }
            e.preventDefault();
            return false;
        });

        const saveButton = this.view.getElementsByClassName("pdf-preview-save")[0] as HTMLButtonElement;
        saveButton.addEventListener("click", () => {
            if (this.onSave) { this.onSave(); }
        });
        this.saveButton = saveButton;

        const closeButton = this.view.getElementsByClassName("pdf-preview-close")[0];
        closeButton.addEventListener("click", () => {
            this.hide();
            if (this.onClose) { this.onClose(); }
        });

        this.saveStyle(this.progressView);
        this.saveStyle(this.previewView);
    }

    getContentHeight(): number {
        const doc = this.previewFrame.contentWindow.document;
        const elm = doc.getElementById("container");
        if (!elm) { return 0; }
        const style = doc.defaultView.getComputedStyle(elm);
        const height = +(style.height.replace("px", ""));
        return isNaN(height) ? 0 : height;
    }

    isHidden(): boolean {
        return this.view.parentNode === null;
    }

    show() {
        if (this.isHidden()) {
            this.container.appendChild(this.view);
        }
    }

    hide() {
        if (!this.isHidden()) {
            this.container.removeChild(this.view);
        }
        this.hideProgress();
        this.hidePreview();
        const images = Array.prototype.slice.call(this.previewView.getElementsByClassName("pdf-preview-item"));
        images.forEach((elm) => {
            elm.parentNode.removeChild(elm);
        });
        this.isSaveButtonVisible = true;
    }

    showProgress() {
        this.show();
        this.restoreStyle(this.progressView);
        this.previewView.style.display = "none";
    }

    hideProgress() {
        this.progressView.style.display = "none";
    }

    get isSaveButtonVisible() {
        return this.saveButton.style.display !== "none";
    }

    set isSaveButtonVisible(visible: boolean) {
        this.saveButton.style.display = visible ? "" : "none";
    }

    showPreview(mode: string) {
        this.show();
        this.saveButton.setAttribute("class", `pdf-preview-save mode-${mode}`);
        this.progressView.style.display = "none";
        this.restoreStyle(this.previewView);
        this.view.querySelector('.pdf-preview-menu').classList.remove('hidden');
    }

    hidePreview() {
        this.previewView.style.display = "none";
        this.view.querySelector('.pdf-preview-menu').classList.add('hidden');
    }

    saveStyle(elm) {
        var style = document.defaultView.getComputedStyle(elm);
        elm.setAttribute("data-display", style.display);
    }

    restoreStyle(elm) {
        const display = elm.getAttribute("data-display");
        elm.style.display = display;
    }

    setProgress(currentValue: number, maxValue: number) {
        const progress = Math.max(0, Math.min(currentValue / maxValue * 100, 100));
        this.progressBar.style.width = progress + "%";
    }

    appendPreview(content: HTMLElement, orientation: PDFPreviewOrientation) {
        const template = document.getElementById("template");
        const item = template.getElementsByClassName("pdf-preview-item")[0].cloneNode(true) as HTMLElement;
        item.classList.add(orientation);
        item.appendChild(content);
        const container = this.previewFrame.contentWindow.document.getElementById("container");
        container.appendChild(item);
    }

    print() {
        this.previewFrame.focus();
        this.previewFrame.contentWindow.print();
    }

    private preventScroll(e) {
        e.preventDefault();
    }
}

export class Notifier {

    public readonly alertbox: HTMLElement;
    public readonly lightbox: HTMLElement;
    public readonly button: HTMLElement;
    public readonly cancelButton: HTMLElement;
    private callback?: (boolean) => void;

    private constructor(message: string, template: HTMLElement, callback: (boolean) => void = null, scroll: boolean = false) {
        this.alertbox = template.cloneNode(true) as HTMLElement;
        if(!scroll) this.alertbox.addEventListener('touchmove', this.preventScroll, false);

        this.callback = callback;

        const messagebox = this.alertbox.querySelector('p');
        messagebox.innerHTML = message;

        this.lightbox = document.createElement('div');
        this.lightbox.setAttribute('class', 'lightbox');
        this.lightbox.setAttribute('style', `height:${document.body.scrollHeight + 'px'}`);
        this.lightbox.addEventListener('touchmove', this.preventScroll, false);

        this.button = this.alertbox.querySelector('.okButton');
        this.button.addEventListener('click', (e) => {
            const callback = this.callback;
            this.callback = null;
            this.close();
            if (callback) { callback(true); }
        });

        const cancelButton = this.alertbox.querySelector('.cancelButton');
        if (cancelButton) {
            cancelButton.addEventListener('click', e => {
                const callback = this.callback;
                this.callback = null;
                this.close();
                if (callback) { callback(false); }
            });
        }
        if(scroll){
            const h1 = this.alertbox.querySelector('h1');
            h1.addEventListener('touchmove',this.preventScroll,false);
            const okButton = this.alertbox.querySelector('.okButton');
            okButton.addEventListener('touchmove',this.preventScroll,false);
        }
        document.body.insertBefore(this.lightbox, document.body.firstChild);
        document.body.appendChild(this.alertbox);
    }

    static show(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.alert") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static unsupport(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.unsupported") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static howto(message: string, callback: (boolean) => void = null, scroll: boolean = true): Notifier {
        const template = document.getElementById("template").querySelector(".notification.howto") as HTMLElement;
        return new Notifier(message, template, callback, true);
    }

    static confirm(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.ending") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static confirm2(message: string, callback: (boolean) => void = null): Notifier {
        const template = document.getElementById("template").querySelector(".notification.confirm.normal") as HTMLElement;
        return new Notifier(message, template, callback);
    }

    static confirmWithLabel(message: string, label: string, callback: (result: boolean) => void): Notifier {
        const view = <HTMLElement>document.getElementById("template").querySelector(".notification.confirm").cloneNode(true);
        view.querySelector(".okButton").textContent = label;
        return new Notifier(message, view, callback);
    }

    preventScroll(e) {
        e.preventDefault();
    }

    close() {
        this.alertbox.parentNode.removeChild(this.alertbox);
        this.lightbox.removeEventListener('touchmove', this.preventScroll, false);
        this.lightbox.parentNode.removeChild(this.lightbox);
        if (this.callback) {
            this.callback(false);
            this.callback = null;
        }
    }
}


/*Bowser*/
const browser = Bowser.getParser(window.navigator.userAgent);
//console.log(browser.parse());
const isValidBrowser = browser.satisfies({
    windows: {
        "internet explorer": "<3", // 消すとisValidBrowserがundefinedになるのでこの形にする
        "microsoft edge": ">16",
        chrome: ">72"
    },
    macos: {
        safari: ">11",
        chrome: ">72"
    },
    iOS: {
        safari: '>11'
    },
    Android: {
        chrome: ">72"
    }
});
// const isiOS = browser.satisfies({
//     mobile: {
//         safari: '>9'
//     },
//     tablet: {
//         safari: '>9'
//     }
// });

const osInfo = browser.parseOS();
const browserInfo = browser.getBrowser();
const isMobile = browser.parsePlatform().type === "mobile";
const isiPad = browserInfo.name === "Safari" && typeof document.ontouchstart !== 'undefined' && browser.parsePlatform().type !== 'mobile';
const isiOS = osInfo.name === 'iOS' || isiPad;
// console.log(isValidBrowser);
// console.log(browserInfo);
// console.log(osInfo);
// console.log(isiPad);
const isiPhone = isiOS && browser.getPlatform().type === 'mobile';
const isIE11 = browserInfo.name === "Internet Explorer" && browserInfo.version === "11.0";
const isAndroid = osInfo.name === 'Android';



const systemInfo = osInfo.name + "-" + osInfo.version + " " + browserInfo.name + "-" + browserInfo.version;

const UNSUPPORTED_BROWSER_MESSAGE = 
`
お使いのブラウザは本サービスに対応していないため、正しく動作しない可能性があります。<br>
以下の推奨ブラウザでのご利用をお願いいたします。
<br><br>●パソコンのブラウザ
<br>・Windows10　/　Edge、Chrome
<br>・macOS (10.14以降推奨)　/　Safari、Chrome
<br>●スマートフォン・タブレットのブラウザ
<br>・iOS(12以降)　/　Safari
<br>・iPadOS(13以降) 　/　Safari
<br>・Android (6以降)　/　Chrome
`;
const ARE_YOU_SURE_TO_LEAVE_MESSAGE = "入力されたデータが失われますが続けますか？";

const HOWTO_DOWNLOAD_IPHONE =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面が表示され「ダウンロードする」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/iphone_safari_qr_1.png">
<span>②QR画像が新しいタブで表示されたら、画面を長押ししてください。</span>
<span>③下にメニューが表示されますので、「写真に追加」を選択してください。</span>
<img src="./res/aizu/iphone_safari_qr_2.png">
<span>④写真ファイルにQRコード画像が保存されていることをご確認ください。</span>
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/iphone_safari_pdf_1.png">
<span>②プレビューで表示された画像が新しいタブで表示されたら、ブラウザ下部メニューの共有ボタンを選択してください。</span>
<img src="./res/aizu/iphone_safari_pdf_2.png">
<span>③共有先の候補一覧が表示されますので、アプリまたはその下に表示されるメニューの「ファイルに保存」などから保存先を選択し保存してください。</span>
<img src="./res/aizu/iphone_safari_pdf_5.png">
<span>④選択した保存先にPDFが保存されていることをご確認ください。 </span>
`;


const HOWTO_DOWNLOAD_IPAD =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面がプレビュー表示されますので、右上の「保存する」ボタンを選択してください。</span>
<img src="./res/aizu/ipad_safari_qr_1.png">
<span>②ダウンロードの確認ポップアップが表示されますので、「ダウンロード」を選択してください。</span>
<img src="./res/aizu/ipad_safari_qr_2.png">
<span>③ブラウザ上部メニューにダウンロードボタンが表示されますので、データが保存されていることをご確認ください。</span>
<img src="./res/aizu/ipad_safari_qr_3.png">
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/ipad_safari_pdf_1.png">
<span>②プレビューで表示された画像が新しいタブで表示されたら、ブラウザ上部メニューの共有ボタンを選択してください。</span>
<img src="./res/aizu/ipad_safari_pdf_2.png">
<span>③共有先の候補一覧が表示されますので、アプリまたはその下に表示されるメニューの「ファイルに保存」などから保存先を選択し保存してください。</span>
<img src="./res/aizu/ipad_safari_pdf_5.png">
<span>④選択した保存先にPDFが保存されていることをご確認ください。</span>
`;

const HOWTO_DOWNLOAD_MACOS =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面がプレビュー表示されますので、右上の「保存する」ボタンを選択してください。</span>
<img src="./res/aizu/macos_safari_qr_1.png">
<span>②ダウンロードの確認ポップアップが表示されますので、「ダウンロード」を選択してください。</span>
<span>③ブラウザ上部メニューのダウンロードボタンから、データが保存されていることをご確認ください。ダウンロードフォルダを開いて、保存場所を確認してください。</span>
<img src="./res/aizu/macos_safari_qr_2.png">
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/macos_safari_pdf_1.png">
<span>②ダウンロードの確認ポップアップが表示されますので、「ダウンロード」を選択してください。</span>
<span>③ブラウザ上部メニューのダウンロードボタンから、データが保存されていることをご確認ください。ダウンロードフォルダを開いて、保存場所を確認してください。</span>
<img src="./res/aizu/macos_safari_pdf_2.png">                        
`;

const HOWTO_DOWNLOAD_WIN10CHROME =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面がプレビュー表示されますので、右上の「保存する」ボタンを選択してください。</span>
<span>②画面下部に「提出書類（会津若松プラスより出力）_〇〇〇〇（作成した申請書の名称）.png」が表示され、ダウンロードが完了します。(画面縮尺によってファイル名は省略されます)</span>
<span>③ファイル名の横のアイコンを選択し、表示されたメニューから「フォルダを開く」を選択することで、保存場所の確認ができます。</span>
<img src="./res/aizu/win_chrome_qr.png">
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<span>②画面下部に「提出書類（会津若松プラスより出力）_〇〇〇〇（作成した申請書の名称）.pdf」が表示され、ダウンロードが完了します。（画面縮尺によってファイル名は省略されます）</span>
<span>③ファイル名の横のアイコンを選択し、表示されたメニューから「フォルダを開く」を選択することで、保存場所の確認ができます。</span>                        
<img src="./res/aizu/win_chrome_pdf.png">
`;

const HOWTO_DOWNLOAD_WIN10EDGE =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面がプレビュー表示されますので、右上の「保存する」ボタンを選択してください。</span>
<span>②画面下部に「提出書類（会津若松プラスより出力）_〇〇〇〇（作成した申請書の名称）.png」が表示され、ダウンロードが完了します。（画面縮尺によってファイル名は省略されます）</span>
<span>③ファイル名の横のアイコンを選択し、表示されたメニューから「フォルダを開く」を選択することで、保存場所の確認ができます。</span>
<img src="./res/aizu/win_edge_qr.png">
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<span>②画面下部に「提出書類（会津若松プラスより出力）_〇〇〇〇（作成した申請書の名称）.pdf」が表示され、ダウンロードが完了します。（画面縮尺によってファイル名は省略されます）</span>
<span>③ファイル名の横のアイコンを選択し、表示されたメニューから「フォルダを開く」を選択することで、保存場所の確認ができます。</span>
<img src="./res/aizu/win_edge_pdf.png">
`;

const HOWTO_DOWNLOAD_ANDROID =
`
<h2>＜QR画像を保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、QRコード画面が表示され「ダウンロードする」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/android_chrome_qr_1.png">
<span>②QR画像が表示されたら、画面を長押ししてください。</span>
<img src="./res/aizu/android_chrome_qr_2.png">
<span>③メニューが表示されますので、「画像をダウンロード」を選択してください。</span>
<img src="./res/aizu/android_chrome_qr_3.png">
<span>④下部に保存確認のメッセージが表示されますので、「開く」を選択し保存されていることをご確認ください。</span>
<img src="./res/aizu/android_chrome_qr_4.png">
<h2>＜PDFとして保存する方法＞</h2>
<span>①「プレビューを作成しています」の表示のあと、入力した申請書と復元用QRコードが表示され「保存する」のボタンが表示されますので、選択してください。</span>
<img src="./res/aizu/android_chrome_pdf_1.png">
<span>②ブラウザのメニューボタンを選択し、「ダウンロード」を選択して保存されていることをご確認ください。</span>
<img src="./res/aizu/android_chrome_pdf_2.png">                    
`;

// const browser_test = document.createElement('p');
// browser_test.setAttribute('id',"ua_debug");
// browser_test.innerHTML =
// `
// ua = ${window.navigator.userAgent}<br>
// browser = ${browserInfo.name}<br>
// os = ${osInfo.name}<br>
// isValidBrowser = ${isValidBrowser}<br>
// isMobile = ${isMobile}<br>
// isiPad = ${isiPad}
// `;
// document.body.appendChild(browser_test);